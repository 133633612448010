import "core-js/modules/es.array.push.js";
import { feeDesc, mixins } from "@/plugins/mixins";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

export default {
  name: "rechargePromotion",
  mixins: [mixins],
  components: {},
  data() {
    return {
      currentId: '',
      detailDrawer: false,
      columns: [{
        type: 'expand',
        typeSlot: 'itemExpand'
      }, {
        label: '活动名称',
        prop: 'promotionName'
      }, {
        label: '活动描述',
        prop: 'promotionDesc',
        showOverflowTooltip: true
      }, {
        label: '开始时间',
        prop: 'beginTime'
      }, {
        label: '结束时间',
        prop: 'endTime'
      }],
      topButtons: [{
        name: '新增',
        code: 'rechargePromotion_add',
        type: 'primary',
        click: this.add,
        permissions: ['rechargePromotion_add']
      }],
      linkButtons: [{
        name: '修改',
        code: 'rechargePromotion_edit',
        click: this.edit,
        permissions: ['rechargePromotion_edit']
      }, {
        name: '删除',
        code: 'rechargePromotion_del',
        type: 'danger',
        click: this.del,
        permissions: ['rechargePromotion_del']
      }],
      searchItems: [{
        prop: 'promotionName',
        label: '名称'
      }]
    };
  },
  methods: {
    add() {
      this.$router.push('/rechargePromotionAdd');
    },
    edit(row) {
      this.$router.push({
        path: '/rechargePromotionAdd',
        query: {
          id: row.id
        }
      });
    },
    del(row) {
      ElMessageBox.confirm('确认要删除该活动吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.business.rechargePromotion.del({
          id: row.id
        }).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    },
    showFeeRuleDesc(feeRule) {
      return feeDesc(feeRule);
    },
    loadItems(item) {
      if (!item.id) {
        return;
      }
      this.$api.business.rechargePromotion.items({
        id: item.id
      }).then(res => {
        if (res.code === 200) {
          item.items = res.data;
        } else {
          ElMessage.error(res.message);
          item.items = [];
        }
      });
      return '加载中';
    }
  },
  beforeMount() {}
};